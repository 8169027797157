import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Input, Spinner } from 'reactstrap';
import axiosService from '../../services/axiosService';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './dashboard.css';
import { toast } from 'react-toastify';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: 'createdAt',
    direction: 'descending',
  });

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axiosService.get('/order/get-all');
        setOrders(response.data.results);
        setFilteredOrders(response.data.results);
      } catch (error) {
        console.error('Error fetching orders:', error);
        toast.error('Error fetching orders');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter(value);
    setFilteredOrders(
      orders.filter((order) => order.buyer.toLowerCase().includes(value))
    );
  };

  const sortBy = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setFilteredOrders((prevFilteredOrders) => {
      return [...prevFilteredOrders].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    });
  };

  if (loading) {
    return (
      <div className="main-dashboard">
        <Container>
          <Spinner color="primary" />
        </Container>
      </div>
    );
  }

  return (
    <div className="main-dashboard">
      <Container>
        <h2>Orders</h2>
        <Input
          type="text"
          placeholder="Filter by buyer name"
          value={filter}
          onChange={handleFilterChange}
        />
        <Table>
          <thead>
            <tr>
              <th onClick={() => sortBy('buyer')}>
                Buyer{' '}
                {sortConfig.key === 'buyer'
                  ? sortConfig.direction === 'ascending'
                    ? '▲'
                    : '▼'
                  : null}
              </th>
              <th onClick={() => sortBy('amount')}>
                Amount{' '}
                {sortConfig.key === 'amount'
                  ? sortConfig.direction === 'ascending'
                    ? '▲'
                    : '▼'
                  : null}
              </th>
              <th onClick={() => sortBy('paymentStatus')}>
                Payment Status{' '}
                {sortConfig.key === 'paymentStatus'
                  ? sortConfig.direction === 'ascending'
                    ? '▲'
                    : '▼'
                  : null}
              </th>
              <th onClick={() => sortBy('isDelivered')}>
                Delivery Status{' '}
                {sortConfig.key === 'isDelivered'
                  ? sortConfig.direction === 'ascending'
                    ? '▲'
                    : '▼'
                  : null}
              </th>
              <th onClick={() => sortBy('status')}>
                Order Status{' '}
                {sortConfig.key === 'status'
                  ? sortConfig.direction === 'ascending'
                    ? '▲'
                    : '▼'
                  : null}
              </th>
              <th onClick={() => sortBy('createdAt')}>
                Created At{' '}
                {sortConfig.key === 'createdAt'
                  ? sortConfig.direction === 'ascending'
                    ? '▲'
                    : '▼'
                  : null}
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr key={order.id}>
                <td>{order.buyer}</td>
                <td>{order.amount}</td>
                <td>{order.paymentStatus}</td>
                <td>{order.isDelivered ? 'Delivered' : 'Not Delivered'}</td>
                <td>{order.status}</td>
                <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                <td>
                  <Button
                    tag={Link}
                    to={`/admin/orders/${order.id}`}
                    color="primary"
                  >
                    View Details
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

Orders.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      buyer: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      paymentStatus: PropTypes.string.isRequired,
      isDelivered: PropTypes.bool.isRequired,
      status: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    })
  ),
};

export default Orders;
