import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import axiosService from '../../services/axiosService';
import { toast } from 'react-toastify';

const AddCategoryModal = ({ isOpen, toggle, categories }) => {
  const { register, handleSubmit, errors } = useForm();
  const [hasParentCategory, setHasParentCategory] = useState(false);
  
  const submitForm = async (data) => {
    try {
      await axiosService.post('/category', data);
      toast.success('Category added successfully');
    } catch (error) {
      console.error('Error adding category:', error);
      toast.error('Error adding category');
    } finally {
      toggle();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Category</ModalHeader>
      <Form onSubmit={handleSubmit(submitForm)}>
        <ModalBody>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              type="text"
              name="title"
              id="title"
              innerRef={register({ required: 'Title is required' })}
            />
            {errors.title && <span>{errors.title.message}</span>}
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="text"
              name="description"
              id="description"
              {...register('description', {
                required: 'Description is required',
              })}
            />
            {errors.description && <span>{errors.description.message}</span>}
          </FormGroup>
          <FormGroup>
            <Label for="type">Type</Label>
            <Input
              type="select"
              name="type"
              id="type"
              {...register('type', {
                required: 'Type is required',
              })}
            >
              <option value="">Select Type</option>
              <option value="Product">Product</option>
              <option value="Service">Service</option>
            </Input>
            {errors.type && <span>{errors.type.message}</span>}
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                onChange={() => setHasParentCategory(!hasParentCategory)}
              />{' '}
              Has Parent Category
            </Label>
          </FormGroup>
          {hasParentCategory && (
            <FormGroup>
              <Label for="parentCategory">Parent Category</Label>
              <Input
                type="select"
                name="parentCategory"
                id="parentCategory"
                innerRef={register}
                {...register('parentCategory')}
              >
                <option value="">Select Parent Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.title}
                  </option>
                ))}
              </Input>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary">
            Add
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

AddCategoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AddCategoryModal;
