import React, { useState } from 'react';
import {
  Button,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
// import { useNavigate } from 'react-router-dom';

import logo from '../assets/images/logos/mcgp-logo.png';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';

export default function MiningLogin() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setError('');
      await login(formData.identifier, formData.password);
      toast.success('Login successful!');
      //   redirect
      navigate('/admin/');
    } catch (error) {
      const errorMessage =
        error.message || 'An error occurred. Please try again later.';
      setError(errorMessage);
    }
  };

  return (
    <>
      <div className="auth-bg">
        <Row>
          <Col lg="4" md="5" sm="12" className="bg-white vh-100">
            <div className="mt-4">
              <CardBody className="card-body p-4">
                <div className="logo-header mb-4">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
                <div className="auth-form">
                  <form onSubmit={submitHandler} className="mb-4">
                    <h3 className="text-center mb-4">Login</h3>
                    <FormGroup>
                      <Label for="identifier">Email / Phone Number</Label>
                      <Input
                        type="text"
                        name="identifier"
                        id="identifier"
                        placeholder="Email or username"
                        className="input"
                        value={formData.identifier}
                        onChange={handleInputChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        className="input"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                      />
                    </FormGroup>
                    {error && <div className="text-danger mb-3">{error}</div>}
                    <div className="mt-4">
                      <Button
                        type="submit"
                        className="btn btn-primary btn-block auth-btn"
                      >
                        Login
                      </Button>
                    </div>
                  </form>
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col>
                    <p className="text-center mb-4">
                      © 2023
                      <span> Millionaire Circle Green Protocol</span>
                    </p>
                  </Col>
                </Row>
              </CardFooter>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
