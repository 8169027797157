import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import './About.css';

import about from '../../assets/images/logos/mcgp-logo.png';
import globe from '../../assets/images/banner_shape01.png';
import { Link } from 'react-router-dom';
import pitch from '../../assets/pitch.pdf';

export default function About() {
  return (
    <>
      <section id="about" className="section-wrapper bg-primary-light">
        <Container>
          <div className="content-inner-1">
            <Row className="align-items-center justify-content-center">
              <Col lg={6}>
                <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
                  <img src={about} alt="about" />
                  <img className="about-left-image" src={globe} alt="about" />
                </div>
              </Col>
              <Col lg={6}>
                <div
                  className="about-content wow fadeInRight"
                  data-wow-delay=".5s"
                >
                  <div className="section-head">
                    <h2 className="title">About us</h2>
                    <p>Millionaire Circle Green Protocol overview</p>
                  </div>
                  <div className="about-text">
                    <p>
                      MCGP is a community driven project own and is for the
                      community. MCGP stands in line with actualization of
                      Tokenization and RWA market valuation of $16 Trillion
                      dollars worth in 2030. As a growing community, we believe
                      to work towards bringing a transparent Tokenization
                      ecosystem platform which will foster a simple and easy go
                      of tokenizing real world assets across globe.
                    </p>
                    <p>
                      Tokenization is the process of converting real-world
                      assets into digital tokens, enabling fractional ownership
                      and increased liquidity. As the MCGP project aims to
                      become the world-leading Tokenization platform, our goal
                      is to revolutionize the way assets are bought, sold, and
                      traded. By providing a secure and transparent platform for
                      tokenizing various assets such as real estate,
                      Agricultural products, securities, and other Real world
                      assets!MCGP is bridging the gap between traditional
                      finance and the digital economy. Through our innovative
                      technology and commitment to compliance, we are empowering
                      investors to diversify their portfolios and access new
                      investment opportunities in a seamless and efficient
                      manner.
                    </p>

                    <div className="d-flex justify-content-around">
                      <Link className="btn-action" to="/about">
                        Learn More
                      </Link>

                      <Link className="btn-action" to={pitch} target="_blank">
                        MCGP Pitch Deck
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}
