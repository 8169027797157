import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Spinner } from 'reactstrap';
import axiosService from '../../services/axiosService';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddCategoryModal from '../../components/modal/AddCategoryModal';

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosService.get('/category');
        setCategories(response.data.results);
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast.error('Error fetching categories');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleDeleteCategory = async (categoryId) => {
    try {
      await axiosService.delete(`/categories/${categoryId}`);
      setCategories(
        categories.filter((category) => category.id !== categoryId)
      );
      toast.success('Category deleted successfully');
    } catch (error) {
      console.error('Error deleting category:', error);
      toast.error('Error deleting category');
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  if (loading) {
    return (
      <div className="main-dashboard">
        <Container>
          <Spinner color="primary" />
        </Container>
      </div>
    );
  }

  return (
    <div className="main-dashboard">
      <Container>
        <h2>Categories</h2>
        <Table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category.id}>
                <td>{category.title}</td>
                <td>{category.description}</td>
                <td>{category.type}</td>
                <td>
                  <Button
                    tag={Link}
                    to={`/admin/categories/${category.id}`}
                    color="info"
                  >
                    Edit
                  </Button>{' '}
                  <Button
                    onClick={() => handleDeleteCategory(category.id)}
                    color="danger"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button color="success" onClick={toggleModal}>
          Add New Category
        </Button>
        <AddCategoryModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          categories={categories}
        />
      </Container>
    </div>
  );
};

export default CategoryList;
