import React, { useState, useEffect } from 'react';
import { Container, Table, Spinner, Alert } from 'reactstrap';
import axiosService from '../../services/axiosService';
import PropTypes from 'prop-types';

import './dashboard.css';

const ViewUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosService.get('/users');
        setUsers(response.data.results);
      } catch (error) {
        console.error('Error fetching users:', error);
        setError('Error fetching users');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return (
      <div className="main-dashboard">
        <Container>
          <Spinner color="primary" />
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div className="main-dashboard">
        <Container>
          <Alert color="danger">{error}</Alert>
        </Container>
      </div>
    );
  }

  return (
    <div className="main-dashboard">
      <Container>
        <h2>Users</h2>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Kyc Status</th>
              <th>Country</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id}>
                <td>{`${user.firstName} ${user.lastName}`}</td>
                <td>{user.email}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.kycVerified ? 'Verified' : 'Not verified'}</td>
                <td>{user.country}</td>
                <td>{user.balance}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

ViewUsers.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string,
      kycVerified: PropTypes.bool,
      country: PropTypes.string,
      balance: PropTypes.number,
    })
  ),
};

export default ViewUsers;
