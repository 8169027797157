import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Login from './pages/Login';
import AuthLayout from './components/layout/AuthLayout';
import Dashboard from './pages/dashboard/Dashboard';
import DashboardLayout from './components/layout/DashboardLayout';
import UserProfile from './pages/dashboard/Profile';
import ViewUsers from './pages/dashboard/Users';
import Adverts from './pages/dashboard/Adverts';
import Orders from './pages/dashboard/Orders';
import CategoryList from './pages/dashboard/Category';
import ComingSoonPage from './components/common/ComingSoon';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/*"
          element={
            <Layout>
              <Routes>
                <Route index element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/coming-soon" element={<ComingSoonPage />} />
              </Routes>
            </Layout>
          }
        />
        <Route
          path="/auth/*"
          element={
            <AuthLayout>
              <Routes>
                <Route index element={<Login />} />
                <Route path="/login" element={<Login />} />
              </Routes>
            </AuthLayout>
          }
        />
        <Route
          path="/admin/*"
          element={
            <DashboardLayout>
              <Routes>
                <Route index element={<Dashboard />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/users" element={<ViewUsers />} />
                <Route path="/adverts" element={<Adverts />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/category" element={<CategoryList />} />
            </Routes>
            </DashboardLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
