import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';
import { FaBars, FaShoppingCart, FaTimes, FaUsers } from 'react-icons/fa';
import logo from '../../assets/images/logos/mcgp-logo.png';
import female from '../../assets/images/female.jpg';
import './dashboard.css';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useAuth } from '../../contexts/AuthContext';

export default function Sidebar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const location = useLocation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  // Function to handle logout
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/auth/login');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="sidenav-container">
        <div className="nav-header">
          <Link to="/" className="brand-logo">
            <img src={logo} alt="logo" className="logo-abbr" />
          </Link>
        </div>

        {(isMobileMenuOpen || !isMobileDevice) && (
          <nav className="sidebar-main">
            <ul className="menu-list">
              <li className="">
                <Link
                  to="/admin"
                  className={`has-arrow ${isActive('/app')}`}
                  onClick={closeMobileMenu}
                >
                  <FiHome className="icon" />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="">
                <Link
                  to="/admin/users"
                  className={`has-arrow ${isActive('/app')}`}
                  onClick={closeMobileMenu}
                >
                  <FaUsers className="icon" />
                  <span>Users</span>
                </Link>
              </li>
              <li className="">
                <Link
                  to="/admin/adverts"
                  className={`has-arrow ${isActive('/app')}`}
                  onClick={closeMobileMenu}
                >
                  <FaShoppingCart className="icon" />
                  <span>Adverts</span>
                </Link>
              </li>
              <li className="">
                <Link
                  to="/admin/orders"
                  className={`has-arrow ${isActive('/app')}`}
                  onClick={closeMobileMenu}
                >
                  <FaShoppingCart className="icon" />
                  <span>Orders</span>
                </Link>
              </li>
              <li className="">
                <Link
                  to="/admin/category"
                  className={`has-arrow ${isActive('/app')}`}
                  onClick={closeMobileMenu}
                >
                  <FiHome className="icon" />
                  <span>Categories</span>
                </Link>
              </li>
            </ul>
          </nav>
        )}

        <div className="header">
          <div className="header-content">
            <nav className="navbar navbar-expand">
              <div className="collapse navbar-collapse justify-content-between">
                <div className="header-left">
                  <div className="dashboard_bar">
                    {isMobileDevice && (
                      <div className="navicon" onClick={toggleMobileMenu}>
                        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
                      </div>
                    )}
                  </div>
                </div>
                <div className="navbar-nav header-right">
                  <div className="header-profile">
                    <UncontrolledDropdown>
                      <DropdownToggle className="p-0" color="empty">
                        <Link className="nav-link">
                          <img
                            src={female}
                            alt="profile"
                            className="profile-pic"
                          />
                        </Link>
                      </DropdownToggle>
                      <DropdownMenu className="mt-3" end>
                        <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
